import jquery from 'jquery';
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import './scss/index.scss';
import '@popperjs/core';
import 'bootstrap';

// moment
import moment from 'moment';
window.moment = moment;

// Handlebars
import Handlebars from 'handlebars/dist/handlebars';
window.Handlebars = Handlebars;

// Fancybox
//import fancybox from '@fancyapps/fancybox';
//import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';

// jquery-mask-plugin
import mask from 'jquery-mask-plugin/dist/jquery.mask';
window.mask = mask;

// bootbox
import bootbox from 'bootbox';
window.bootbox = bootbox;

// maxlength
import maxlength from 'bootstrap-maxlength/dist/bootstrap-maxlength';
window.maxlength = maxlength;

// dropzone
import Dropzone from "dropzone";
window.Dropzone = Dropzone;
// Optionally, import the dropzone file to get default styling.
//import "dropzone/dist/dropzone.css";

// DOMContentLoaded  start
document.addEventListener('DOMContentLoaded', function () {
  window.addEventListener('scroll', function () {
    if (window.scrollY > 50) {
      document.getElementById('navbar_top').classList.add('fixed-top');
      // add padding top to show content behind navbar
      var navbar_height = document.querySelector('.navbar').offsetHeight;
      document.body.style.paddingTop = navbar_height + 'px';
    } else {
      document.getElementById('navbar_top').classList.remove('fixed-top');
      // remove padding top from body
      document.body.style.paddingTop = '0';
    }
  });
});
// DOMContentLoaded  end

$(function(){

  $(window).scrollTop(0);
  document.body.style.paddingTop = '0';
  document.getElementById('navbar_top').classList.remove('fixed-top');

  $(document.body).prepend('<div id="page-loading-overlay"><div class="loader"></div></div>');
  $(document.body).prepend("<div id='modal-sidebar-overlay'></div>");

  set_maxlength();

});

function set_maxlength(){
							
	$('.maxlength').maxlength({
	  alwaysShow: true,
	  threshold: 10,
	  warningClass: "label label-success",
	  limitReachedClass: "label label-danger",
	  separator: ' di ',
	  preText: ' ',
	  postText: ' ',
	  validate: true, 
    appendToParent: true, 
    placement: 'bottom-right-inside'
	});

}
/* IUBENDA */	 
var loadIubendaBadge = function () {
  var s = document.createElement("script"),
    tag = document.getElementsByTagName("script")[0];
  s.src = "https://cdn.iubenda.com/iubenda.js";
  tag.parentNode.insertBefore(s, tag);
  };

  $(".iubenda-link").each(function (index, element) {
  var iub_link = $(
    '<a href="https://www.iubenda.com/' +
    $(this).data("source") +
    "/" +
    $(this).data("id") +
    ($(this).data("specific") != "" ? "/" : "") +
    $(this).data("specific") +
    '" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe ' +
    $(this).data("class") +
    '" title="' +
    $(this).data("text") +
    '">' +
    $(this).data("text") +
    "</a>"
  );
  $(this).append(iub_link);
  });

  loadIubendaBadge();